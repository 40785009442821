import React, { Component } from "react";

import { connect } from "react-redux";
class RecentSearches extends Component {
  render() {
    return (
      <div>
        <h1>Recent Searches</h1>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formStep: state.search.formStep
});

export default connect(mapStateToProps)(RecentSearches);
