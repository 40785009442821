import React, { Component } from "react";

import { connect } from "react-redux";
class Exports extends Component {
  render() {
    return (
      <div>
        <h1>Exports</h1>
        <h2>No Results</h2>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formStep: state.search.formStep
});

export default connect(mapStateToProps)(Exports);
