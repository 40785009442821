import axios from "axios";
import _ from "lodash";
export const SUB_CITIES_REQUEST = "SUB_CITIES_REQUEST";
export const SUB_CITIES_SUCCESS = "SUB_CITIES_SUCCESS";

export const REMOVE_SUB_CITY = "REMOVE_SUB_CITY";

export const removeSubCity = name => ({
  type: REMOVE_SUB_CITY,
  address: name
});
export const subCitiesRequest = () => ({
  type: SUB_CITIES_REQUEST
});
export const subCitiesSuccess = cities => ({
  type: SUB_CITIES_SUCCESS,
  cities
});

export const fetchSubCities = radius => (dispatch, getState) => {
  const selectedCity = getState().search.selectedCity;
  dispatch(subCitiesRequest());
  axios
    .get(`/api/v1/cities/`, {
      params: {
        limit: 10,
        offset: 0,
        location: `${selectedCity.latitude}${
          selectedCity.longitude < 0 ? "" : "+"
        }${selectedCity.longitude}`,
        radius: radius
      }
    })
    .then(({ data }) => {
      console.log(data);
      dispatch(subCitiesSuccess(_.filter(data.data, { type: "CITY" })));
    });
};
