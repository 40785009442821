import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCvwMNIZ9jx-VOLb6UatmbCZJ88xRtix6E",
  authDomain: "sitefinder-merchants-retail.firebaseapp.com",
  databaseURL: "https://sitefinder-merchants-retail.firebaseio.com",
  projectId: "sitefinder-merchants-retail",
  storageBucket: "sitefinder-merchants-retail.appspot.com",
  messagingSenderId: "119091133354",
  appId: "1:119091133354:web:3466cae05273b383"
};

export const myFirebase = firebase.initializeApp(config);
const baseDb = myFirebase.firestore();
export const db = baseDb;
