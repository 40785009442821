import { SET_CITY, CHANGE_FORMSTEP } from "../actions/";

export default (
  state = {
    formStep: 1,
    selectedCity: { address: "", paceId: null }
  },
  action
) => {
  switch (action.type) {
    case SET_CITY:
      return {
        ...state,
        selectedCity: action.city
      };
    case CHANGE_FORMSTEP:
      return {
        ...state,
        formStep: action.step
      };
    default:
      return state;
  }
};
