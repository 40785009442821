import { combineReducers } from "redux";

import user from "./user";
import auth from "./authentication";
import search from "./search";
import subCities from "./subCities";
import cities from "./cities";
import savedSearch from "./savedSearch";
export default combineReducers({
  user,
  auth,
  search,
  subCities,
  cities,
  savedSearch
});
