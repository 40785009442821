import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PrivateRoute from "../components/PrivateRoute";
import Home from "./Home";
import Login from "./Login";

class App extends Component {
  render() {
    return (
      <>
        <Route
          exact
          path="/"
          render={() =>
            this.props.isAuthenticated ? (
              <Redirect to="/home" />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          path="/login"
          render={props => (
            <Login {...props} isFetching={this.props.isFetching} />
          )}
        />
        <PrivateRoute
          path="/home"
          component={Home}
          isAuthenticated={this.props.isAuthenticated}
          isVerifying={this.props.isVerifying}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    isFetching: state.auth.isFetching
  };
}
export default connect(mapStateToProps)(App);
