import {
  SUB_CITIES_REQUEST,
  SUB_CITIES_SUCCESS,
  REMOVE_SUB_CITY
} from "../actions/";

import _ from "lodash";

export default (
  state = {
    isFetching: false,
    cities: []
  },
  action
) => {
  switch (action.type) {
    case SUB_CITIES_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case SUB_CITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        cities: action.cities
      };

    case REMOVE_SUB_CITY:
      return {
        ...state,
        cities: _.filter(state.cities, city => city.city !== action.address)
      };
    default:
      return state;
  }
};
