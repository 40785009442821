import { createBrowserHistory } from "history";

let history;

export default options => {
  if (!history) {
    history = createBrowserHistory(options);
  }

  return history;
};
