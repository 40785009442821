import React, { Component } from "react";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import Slider from "@material-ui/core/Slider";

import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/styles";

import {
  fetchSavedSearch,
  saveCurrentSearch,
  deleteSearch,
  changeFormStep
} from "../actions";
import { getLatLng } from "react-places-autocomplete";
import LocationSearch from "../components/LocationSearch";

const styles = {
  root: {
    flexGrow: 1,
    padding: 20
  },
  paper: {
    padding: 20,
    marginTop: 20
  },
  control: {
    padding: "10%"
  },
  textField: {
    width: "100%"
  },
  center: {
    textAlign: "center",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20
  }
};
function valuetext(value) {
  return `${value} mi`;
}

const defaultValues = {
  docId: 0,
  population: 125108,
  populationGrowth: 1.1,
  housingUnits: 15000,
  households: 12000,
  homeValue: 250000,
  rent: 1100,
  renterOccupied: 30,
  householdIncome: 74647,
  income: 34369,
  poverty: 5,
  preset: "One",
  stabilizationRate: 90,
  expectedMargin: 65,
  otherInc: 4,
  yieldPer: 9.5,
  savedSearch: "default"
};
class Parameters extends Component {
  state = {
    population: "",
    populationGrowth: "",
    housingUnits: "",
    households: "",
    homeValue: "",
    rent: "",
    renterOccupied: "",
    householdIncome: "",
    income: "",
    poverty: "",
    preset: "One",
    stabilizationRate: "",
    expectedMargin: "",
    otherInc: "",
    yieldPer: "",
    savedSearch: "",
    isChanged: false,
    docId: "",
    submitted: false
  };

  componentDidMount() {
    this.props.dispatch(fetchSavedSearch());
  }
  handleNext = () => {
    const { dispatch, cities, subCities, formStep } = this.props;
    if (subCities.length > 0 || cities.length > 0) {
      dispatch(changeFormStep(formStep + 1));
    }
  };

  handleSubmit = () => {
    const {
      population,
      populationGrowth,
      housingUnits,
      households,
      homeValue,
      rent,
      renterOccupied,
      householdIncome,
      income,
      poverty,
      preset,
      stabilizationRate,
      expectedMargin,
      otherInc,
      yieldPer
    } = this.state;
    const isValid =
      population &&
      populationGrowth &&
      housingUnits &&
      households &&
      homeValue &&
      rent &&
      renterOccupied &&
      householdIncome &&
      income &&
      poverty &&
      preset &&
      stabilizationRate &&
      expectedMargin &&
      otherInc &&
      yieldPer;

    if (isValid) {
      this.props.dispatch(changeFormStep(4));
    } else {
      this.setState({ submitted: true });
    }
  };
  saveCurrentSearch = () => {
    const { dispatch, savedSearches } = this.props;
    const {
      population,
      populationGrowth,
      housingUnits,
      households,
      homeValue,
      rent,
      renterOccupied,
      householdIncome,
      income,
      poverty,
      preset,
      stabilizationRate,
      expectedMargin,
      otherInc,
      yieldPer,
      savedSearch,
      isChanged
    } = this.state;
    if (savedSearch && isChanged) {
      dispatch(
        saveCurrentSearch({
          population,
          populationGrowth,
          housingUnits,
          households,
          homeValue,
          rent,
          renterOccupied,
          householdIncome,
          income,
          poverty,
          preset,
          stabilizationRate,
          expectedMargin,
          otherInc,
          yieldPer,
          savedSearch
        })
      );
    }
  };
  handleChange = name => evt => {
    if (name !== "preset") {
      this.setState({
        [name]: evt.target.value.replace(/\D/g, ""),
        isChanged: true,
        savedSearch: ""
      });
    } else {
      this.setState({
        [name]: evt.target.value,
        isChanged: true,
        savedSearch: ""
      });
    }
  };

  handleChangeName = evt => {
    this.setState({ savedSearch: evt.target.value });
  };

  handleSavedSearchSelect = evt => {
    const { savedSearches } = this.props;
    const search =
      evt.target.value === "0"
        ? defaultValues
        : _.find(savedSearches, { docId: evt.target.value });
    if (search && evt.target.value !== "") {
      this.setState({ ...search, isChanged: false });
    }
  };
  handleDelete = id => evt => {
    this.props.dispatch(deleteSearch(id));
    this.setState({ docId: "" });
  };

  render() {
    const {
      selectedCity,
      subCities,
      classes,
      cities,
      savedSearches
    } = this.props;
    const {
      population,
      populationGrowth,
      housingUnits,
      households,
      homeValue,
      rent,
      renterOccupied,
      householdIncome,
      income,
      poverty,
      preset,
      stabilizationRate,
      expectedMargin,
      otherInc,
      yieldPer,
      savedSearch,
      isChanged,
      docId,
      submitted
    } = this.state;
    return (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid item xs={10} lg={10}>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="p">
              Parameters Entry
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Saved Parameters"
                  className={classes.textField}
                  value={docId}
                  onChange={this.handleSavedSearchSelect}
                  SelectProps={{
                    native: true
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  <option value="" />
                  <option value="0">Company Defaults</option>
                  {_.map(savedSearches, search => {
                    return (
                      <option value={search.docId}>{search.savedSearch}</option>
                    );
                  })}
                </TextField>
              </Grid>
              {isChanged ? (
                <>
                  <Grid item xs={6} lg={3}>
                    <TextField
                      id="outlined-name"
                      label={"Saved Search Name"}
                      className={classes.textField}
                      onChange={this.handleChangeName}
                      margin="normal"
                      value={savedSearch}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Button
                      variant="outlined"
                      color={"primary"}
                      disabled={!savedSearch}
                      onClick={this.saveCurrentSearch}
                      style={{ marginLeft: "20px", marginTop: 23 }}
                    >
                      Save Current Search
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item xs={6} lg={4}>
                  <Button
                    variant="outlined"
                    color={"primary"}
                    disabled={savedSearch === "default" || savedSearch === ""}
                    onClick={this.handleDelete(docId)}
                    style={{ marginLeft: "20px", marginTop: 23 }}
                  >
                    Delete Saved Parameters
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="p">
              Demographics
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-name"
                  error={submitted && !population}
                  label="Minimum Population"
                  className={classes.textField}
                  onChange={this.handleChange("population")}
                  margin="normal"
                  value={population}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  error={submitted && !populationGrowth}
                  className={classes.textField}
                  variant="outlined"
                  label="Population Growth"
                  value={populationGrowth}
                  margin="normal"
                  onChange={this.handleChange("populationGrowth")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  error={submitted && !housingUnits}
                  variant="outlined"
                  label="Number of Housing Units"
                  value={housingUnits}
                  margin="normal"
                  onChange={this.handleChange("housingUnits")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  error={submitted && !households}
                  variant="outlined"
                  label="Number of Households"
                  value={households}
                  margin="normal"
                  onChange={this.handleChange("households")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  error={submitted && !homeValue}
                  variant="outlined"
                  label="Median Home Value"
                  value={homeValue}
                  margin="normal"
                  onChange={this.handleChange("homeValue")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  error={submitted && !rent}
                  variant="outlined"
                  label="Median Rent"
                  value={rent}
                  margin="normal"
                  onChange={this.handleChange("rent")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  variant="outlined"
                  label="Renter Occupied"
                  error={submitted && !renterOccupied}
                  value={renterOccupied}
                  margin="normal"
                  onChange={this.handleChange("renterOccupied")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  variant="outlined"
                  error={submitted && !householdIncome}
                  label="Median Household Income"
                  value={householdIncome}
                  margin="normal"
                  onChange={this.handleChange("householdIncome")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  variant="outlined"
                  error={submitted && !income}
                  label="Per Capita Income"
                  value={income}
                  margin="normal"
                  onChange={this.handleChange("income")}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  variant="outlined"
                  label="Persons in Poverty"
                  error={submitted && !poverty}
                  value={poverty}
                  margin="normal"
                  onChange={this.handleChange("poverty")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="p">
              Construction/Site Costs
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Presets"
                  className={classes.textField}
                  value={preset}
                  onChange={this.handleChange("preset")}
                  SelectProps={{
                    native: true
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  <option value="one">One Story Preset</option>
                  <option value="three">Three Story Preset</option>
                </TextField>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="p">
              Operation
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  variant="outlined"
                  label="Stabilization Rate"
                  error={submitted && !stabilizationRate}
                  value={stabilizationRate}
                  margin="normal"
                  onChange={this.handleChange("stabilizationRate")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  variant="outlined"
                  label="Expected Margin"
                  value={expectedMargin}
                  error={submitted && !expectedMargin}
                  margin="normal"
                  onChange={this.handleChange("expectedMargin")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  variant="outlined"
                  label="Other Income"
                  error={submitted && !otherInc}
                  value={otherInc}
                  margin="normal"
                  onChange={this.handleChange("otherInc")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="p">
              Investment
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={4}>
                <TextField
                  id="outlined-adornment-weight"
                  className={classes.textField}
                  variant="outlined"
                  label="Yield"
                  value={yieldPer}
                  error={submitted && !yieldPer}
                  margin="normal"
                  onChange={this.handleChange("yieldPer")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <div className={classes.center}>
            <Button
              variant="outlined"
              color={"primary"}
              onClick={this.handleSubmit}
              className={classes.center}
            >
              Calculate Results
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  selectedCity: state.search.selectedCity,
  subCities: state.subCities.cities,
  formStep: state.search.formStep,
  cities: state.cities.cities,
  savedSearches: state.savedSearch.savedSearches
});
export default withStyles(styles)(connect(mapStateToProps)(Parameters));
