import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser } from "../actions";
import { Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class Login extends Component {
  state = {
    password: "",
    email: "",
    passwordValid: true,
    emailValid: true,
    validated: false
  };
  handleSubmit = () => {
    const { password, email } = this.state;
    const { dispatch } = this.props;
    const newState = { validated: true };
    if (!password) {
      newState.passwordValid = false;
    } else if (!email) {
      newState.emailValid = false;
    } else {
      dispatch(loginUser({ password, email }));
    }
    this.setState(newState);
  };

  handlePasswordChange = evt => {
    this.setState({ password: evt.target.value, passwordValid: true });
  };

  handleEmailChange = evt => {
    this.setState({ email: evt.target.value, emailValid: true });
  };
  render() {
    const { auth } = this.props;
    const { password, email, passwordValid, emailValid } = this.state;
    return (
      <Container style={{ paddingTop: 100 }}>
        <Row className="justify-content-center">
          <Card style={{ width: "20rem" }}>
            <Card.Header as="h5" className="text-center">
              Login
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    isInvalid={!emailValid}
                    value={email}
                    onChange={this.handleEmailChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    isInvalid={!passwordValid || auth.errorMessage}
                    value={password}
                    onChange={this.handlePasswordChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {auth.errorMessage
                      ? "Username/Password Incorrect"
                      : "Please enter a password."}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  variant="primary"
                  type="button"
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Row>
        {this.props.auth.isAuthenticated && <Redirect to="/home" />}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(Login);
