import React, { Component } from "react";

import { connect } from "react-redux";
import { setCity, changeFormStep } from "../actions";
import { getLatLng } from "react-places-autocomplete";
import LocationSearch from "../components/LocationSearch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import { withStyles } from "@material-ui/styles";

const styles = {
  root: {
    flexGrow: 1,
    padding: 20
  },
  paper: {
    padding: 20
  },
  control: {
    padding: "10%"
  }
};

class CitySelection extends Component {
  handleSelect = address => {
    const { dispatch } = this.props;
    console.log(address);
    getLatLng(address).then(latLng => {
      dispatch(
        setCity({
          address: address.formatted_address,
          placeId: address.placeId,
          latitude: latLng.lat,
          longitude: latLng.lng
        })
      );
    });
  };

  handleNext = () => {
    const { dispatch, selectedCity, formStep } = this.props;
    if (selectedCity) {
      dispatch(changeFormStep(formStep + 1));
    }
  };
  render() {
    const { selectedCity, classes } = this.props;
    const zoom = selectedCity.address ? 11 : 4;
    return (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid item xs={12} lg={7}>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="p">
              Select a Starting City:
            </Typography>
            <LocationSearch
              searchOptions={{
                types: ["(cities)"],
                componentRestrictions: { country: "us" }
              }}
              handleSelect={this.handleSelect}
            />
            {selectedCity.address && (
              <>
                <strong>Selected City: </strong>
                <Chip
                  label={selectedCity.address}
                  className={classes.chip}
                  variant="outlined"
                />
                <Button
                  variant="outlined"
                  color={"primary"}
                  onClick={this.handleNext}
                  style={{ marginLeft: "20px" }}
                >
                  Next
                </Button>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  selectedCity: state.search.selectedCity,
  formStep: state.search.formStep
});
export default withStyles(styles)(connect(mapStateToProps)(CitySelection));
