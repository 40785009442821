import React, { Component } from "react";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";

import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import Slider from "@material-ui/core/Slider";

import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/styles";

import {
  addCity,
  removeCity,
  removeSubCity,
  changeFormStep,
  fetchSubCities
} from "../actions";
import { getLatLng } from "react-places-autocomplete";
import LocationSearch from "../components/LocationSearch";

const styles = {
  root: {
    flexGrow: 1,
    padding: 20
  },
  paper: {
    padding: 20,
    marginBottom: 20
  },
  control: {
    padding: "10%"
  }
};
function valuetext(value) {
  return `${value} mi`;
}
class SubCitySelection extends Component {
  state = {
    radius: 10
  };
  handleSlideChange = (evt, value) => {
    this.setState({ radius: value });
  };
  handleFetch = () => {
    this.props.dispatch(fetchSubCities(this.state.radius));
  };

  handleAdd = address => {
    const { dispatch } = this.props;
    getLatLng(address).then(latLng => {
      dispatch(
        addCity({
          address: address.formatted_address,
          placeId: address.placeId,
          latitude: latLng.lat,
          longitude: latLng.lng
        })
      );
    });
  };
  deleteAutoAdded = name => () => {
    this.props.dispatch(removeSubCity(name));
  };
  deleteAddedCity = name => () => {
    this.props.dispatch(removeCity(name));
  };
  handleNext = () => {
    const { dispatch, cities, subCities, formStep } = this.props;
    if (subCities.length > 0 || cities.length > 0) {
      dispatch(changeFormStep(formStep + 1));
    }
  };
  render() {
    const { selectedCity, subCities, classes, cities } = this.props;
    const { radius } = this.state;
    return (
      <Grid container className={classes.root} spacing={2} justify="center">
        <Grid item xs={10} lg={7}>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="p" gutterBottom>
              Add cities close to {selectedCity.address}
            </Typography>
            <Typography id="discrete-slider" gutterBottom>
              Radius: {radius}mi
            </Typography>
            <Slider
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              onChange={this.handleSlideChange}
              step={1}
              value={radius}
              min={1}
              max={50}
            />
            <Button
              variant="outlined"
              color={"primary"}
              onClick={this.handleFetch}
            >
              Add Cities
            </Button>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5" component="p">
              Manually Add Cities
            </Typography>
            <LocationSearch
              searchOptions={{
                types: ["(cities)"],
                componentRestrictions: { country: "us" }
              }}
              handleSelect={this.handleAdd}
            />
          </Paper>
          <Paper className={classes.paper}>
            {subCities.length > 0 ||
              (cities.length > 0 && (
                <Typography variant="h5" component="p">
                  Added Cities
                </Typography>
              ))}
            <List>
              {subCities.length > 0 && (
                <>
                  <ListSubheader component="div">
                    Auto Added Cities
                  </ListSubheader>
                  {_.map(subCities, city => {
                    return (
                      <ListItem key={city.city}>
                        <ListItemText
                          primary={`${city.city}, ${city.regionCode}, USA`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={this.deleteAutoAdded(city.city)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </>
              )}
              {cities.length > 0 && (
                <>
                  <ListSubheader component="div">
                    Manually Added Cities
                  </ListSubheader>
                  {_.map(cities, city => {
                    return (
                      <ListItem key={city.address}>
                        <ListItemText primary={`${city.address}`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={this.deleteAddedCity(city.address)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </>
              )}
            </List>
            <Button
              variant="outlined"
              color={"primary"}
              onClick={this.handleNext}
              disabled={cities.length === 0 && subCities.length === 0}
            >
              Next
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  selectedCity: state.search.selectedCity,
  subCities: state.subCities.cities,
  formStep: state.search.formStep,
  cities: state.cities.cities
});
export default withStyles(styles)(connect(mapStateToProps)(SubCitySelection));
