export const SET_CITY = "SET_CITY";
export const CHANGE_FORMSTEP = "CHANGE_FORMSTEP";

export const setCity = city => ({
  type: SET_CITY,
  city
});

export const changeFormStep = step => ({
  type: CHANGE_FORMSTEP,
  step
});
