import { db } from "../firebase/firebase";
import { myFirebase } from "../firebase/firebase";

export const SAVE_SEARCH_REQUEST = "SAVE_SEARCH_REQUEST";
export const SAVE_SEARCH_SUCCESS = "SAVE_SEARCH_SUCCESS";
export const SAVE_SEARCH = "SAVE_SEARCH";
export const DELETE_SEARCH = "DELETE_SEARCH";

const requestSavedSearch = () => {
  return {
    type: SAVE_SEARCH_REQUEST
  };
};

const receiveSavedSearch = data => {
  return {
    type: SAVE_SEARCH_SUCCESS,
    data
  };
};

const saveSearch = payload => {
  return {
    type: SAVE_SEARCH,
    payload
  };
};

const deleteSearchInternal = id => ({
  type: DELETE_SEARCH,
  id
});

export const deleteSearch = id => dispatch => {
  const docRef = db.collection("savedSearches").doc(id);
  docRef.delete().then(doc => {
    dispatch(deleteSearchInternal(id));
  });
};
export const saveCurrentSearch = payload => dispatch => {
  const uid = myFirebase.auth().currentUser.uid;
  const docRef = db.collection("savedSearches");
  docRef.add({ userId: uid, ...payload }).then(doc => {
    dispatch(saveSearch({ ...payload, docId: doc.id }));
  });
};

export const fetchSavedSearch = () => dispatch => {
  const uid = myFirebase.auth().currentUser.uid;
  const docRef = db.collection("savedSearches").where("userId", "==", uid);
  const data = [];
  dispatch(requestSavedSearch());
  docRef.get().then(querySnapshot => {
    querySnapshot.forEach(function(doc) {
      data.push({ docId: doc.id, ...doc.data() });
    });
    dispatch(receiveSavedSearch(data));
  });
};
