import React from "react";
import App from "./containers/App";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import configureStore from "./configureStore";
import getHistory from "./helpers/getHistory";

const store = configureStore();

function Root() {
  return (
    <Provider store={store}>
      <Router history={getHistory()}>
        <App />
      </Router>
    </Provider>
  );
}

export default Root;
