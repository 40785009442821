import {
  SAVE_SEARCH_REQUEST,
  SAVE_SEARCH_SUCCESS,
  SAVE_SEARCH,
  DELETE_SEARCH
} from "../actions/";

import _ from "lodash";

export default (
  state = {
    savedSearches: [],
    isFetching: false
  },
  action
) => {
  switch (action.type) {
    case SAVE_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case SAVE_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        savedSearches: action.data
      };
    case SAVE_SEARCH:
      return {
        ...state,
        savedSearches: [...state.savedSearches, action.payload]
      };
    case DELETE_SEARCH:
      return {
        ...state,
        savedSearches: _.filter(state.savedSearches, search => {
          return search.docId !== action.id;
        })
      };
    default:
      return state;
  }
};
