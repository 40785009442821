import React, { Component } from "react";

import { connect } from "react-redux";
import CitySelection from "../components/CitySelection";
import SubCitySelection from "../components/SubCitySelection";
import FormProgress from "../components/FormProgress";
import Parameters from "../components/Parameters";
class Search extends Component {
  render() {
    const { formStep } = this.props;
    return (
      <div>
        <FormProgress />
        {formStep === 1 && <CitySelection />}
        {formStep === 2 && <SubCitySelection />}
        {formStep === 3 && <Parameters />}
        {formStep === 4 && <h1>Coming Soon</h1>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formStep: state.search.formStep
});

export default connect(mapStateToProps)(Search);
