import { USER_REQUEST, USER_SUCCESS, USER_FAILURE } from "../actions/";

export default (
  state = {
    isFetching: false,
    user: {}
  },
  action
) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.user
      };
    case USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message
      };
    default:
      return state;
  }
};
