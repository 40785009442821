import { myFirebase } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import { currentUserData } from "./user";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

const requestLogin = creds => {
  return {
    type: LOGIN_REQUEST,
    creds
  };
};

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user: user
  };
};

const loginError = message => {
  return {
    type: LOGIN_FAILURE,
    message
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

export const loginUser = creds => dispatch => {
  dispatch(requestLogin(creds));
  console.log("lol");
  myFirebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password)
    .then(user => {
      const docRef = db.collection("users").doc(user.user.uid);
      docRef.get().then(docSnapshot => {
        if (docSnapshot.exists) {
          dispatch(receiveLogin(user));
        } else {
          docRef.set({ firstLoginDate: Date.now() }).then(() => {
            dispatch(receiveLogin(user));
          });
        }
      });
      dispatch(receiveLogin(user));
    })
    .catch(error => {
      dispatch(loginError(error.message));
    });
};

export const logoutUser = () => dispatch => {
  dispatch(requestLogout());
  myFirebase
    .auth()
    .signOut()
    .then(function() {
      dispatch(receiveLogout());
    })
    .catch(function(error) {
      dispatch(logoutError());
    });
};

export const verifyAuth = () => dispatch => {
  dispatch(verifyRequest());
  myFirebase.auth().onAuthStateChanged(user => {
    if (user !== null) {
      dispatch(receiveLogin(user));
      dispatch(currentUserData(user.uid));
    }
    dispatch(verifySuccess());
  });
};
