import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

class LocationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => this.props.handleSelect(results[0]))
      .catch(error => console.error("Error", error));
  };

  render() {
    const { searchOptions } = this.props;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              id="outlined-bare"
              margin="normal"
              variant="outlined"
              label="Search Cities"
              style={{ width: "100%" }}
              inputProps={{
                ...getInputProps({
                  placeholder: "Search Cities ...",
                  className: "location-search-input"
                })
              }}
            />

            <div className="autocomplete-dropdown-container">
              <List>
                {loading && (
                  <ListItem>
                    <ListItemText primary="Loading..." />
                  </ListItem>
                )}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <ListItem
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <ListItemText>{suggestion.description}</ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearch;
