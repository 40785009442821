import { db } from "../firebase/firebase";
import { myFirebase } from "../firebase/firebase";

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";

const requestUser = creds => {
  return {
    type: USER_REQUEST,
    creds
  };
};

const receiveUser = user => {
  return {
    type: USER_SUCCESS,
    user: user
  };
};

const userError = message => {
  return {
    type: USER_FAILURE,
    message
  };
};

export const currentUserData = () => dispatch => {
  const uid = myFirebase.auth().currentUser.uid;
  const docRef = db.collection("users").doc(uid);
  dispatch(requestUser());
  docRef
    .get()
    .then(user => {
      dispatch(receiveUser(user.data()));
    })
    .catch(error => {
      dispatch(userError(error));
    });
};
