export const ADD_CITY = "ADD_CITY";
export const REMOVE_CITY = "REMOVE_CITY";

export const addCity = city => ({
  type: ADD_CITY,
  city
});

export const removeCity = name => ({
  type: REMOVE_CITY,
  address: name
});
