import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";

const styles = {
  root: {
    flexGrow: 1,
    padding: 20
  },
  paper: {
    padding: 20,
    minHeight: 300
  },
  control: {
    padding: "10%"
  },
  muted: {
    color: "#777"
  }
};

class Overview extends Component {
  render() {
    const { currentUser, classes } = this.props;
    console.log(currentUser);
    return (
      <Grid container xs={12} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            Welcome back, {currentUser.displayName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="p" gutterBottom>
              Recent Searches
            </Typography>
            <Divider style={{ marginBottom: 20 }} />
            <Typography
              variant="h6"
              component="p"
              gutterBottom
              className={classes.muted}
            >
              No Results Found
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="p" gutterBottom>
              Results Shared
            </Typography>
            <Divider style={{ marginBottom: 20 }} />
            <Typography
              variant="h6"
              component="p"
              gutterBottom
              className={classes.muted}
            >
              No Results Found
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="p" gutterBottom>
              Highest Calculated Yield
            </Typography>
            <Divider style={{ marginBottom: 20 }} />
            <Typography
              variant="h6"
              component="p"
              gutterBottom
              className={classes.muted}
            >
              No Results Found
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="p" gutterBottom>
              Saved Exports
            </Typography>
            <Divider style={{ marginBottom: 20 }} />
            <Typography
              variant="h6"
              component="p"
              gutterBottom
              className={classes.muted}
            >
              No Results Found
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  formStep: state.search.formStep,
  currentUser: state.user.user
});

export default withStyles(styles)(connect(mapStateToProps)(Overview));
