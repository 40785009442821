import { ADD_CITY, REMOVE_CITY } from "../actions/";
import _ from "lodash";

export default (
  state = {
    cities: []
  },
  action
) => {
  switch (action.type) {
    case ADD_CITY:
      return {
        ...state,
        cities: [...state.cities, action.city]
      };

    case REMOVE_CITY:
      return {
        ...state,
        cities: _.filter(state.cities, city => city.address !== action.address)
      };
    default:
      return state;
  }
};
