import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
const useStyles = makeStyles(theme => ({
  root: {
    background: "none"
  },
  noBackground: {
    background: "none"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function getSteps() {
  return ["Select City", "Selct Sub-Cities", "Add Parameters", "Results"];
}

function FormProgress(props) {
  const classes = useStyles();
  const steps = getSteps();
  const activeStep = props.formStep - 1;
  return (
    <Grid container className={classes.root} spacing={2} justify="center">
      <Grid item xs={10}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.noBackground}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => ({
  formStep: state.search.formStep
});
export default connect(mapStateToProps)(FormProgress);
